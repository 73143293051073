import { Box, Button, Input, Text, Popover, PopoverTrigger, PopoverContent, PopoverBody, List, ListItem, Grid, Icon } from "@chakra-ui/react";
import { isApproved } from "../web3";
import { chains } from "../context/chains/chains";
import { tokens } from "../context/tokens/tokens";
import { useBridge } from "../context/hooks/bridgecontext";
import MessageProgress from "./bridgechecker";
import { useWallet } from "../context/hooks/walletcontext";
import { isValidChain } from "../context/helper";
import { GoArrowRight } from "react-icons/go";

function BridgeForm() {

  const {
    amount,
    sourceToken,
    srcChainId,
    dstChainId,
    sourceTokenBalance,
    isApprove,
    sendParamsFee,
    hash,
    handleApprove,
    handleBridge,
    setAmount,
  } = useBridge();

  const { status } = useWallet();


  // const popoverSrcToken = (
  //   <Popover>
  //     <PopoverTrigger>
  //       <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} minW={'200px'}>{sourceToken.name}</Button>
  //     </PopoverTrigger>
  //     <PopoverContent>
  //       <PopoverBody boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" bgImage="linear-gradient(to right, #0095B6, #00B6D0)" >
  //         <List>
  //           {tokens && Object.keys(tokens)
  //             .map((key) => {
  //               if (srcChainId && dstChainId) {
  //                 return (
  //                   tokens[key].addresses && tokens[key].addresses[srcChainId] && tokens[key].addresses[dstChainId] ? (
  //                     <ListItem key={key} onClick={() => handleSourceTokenChange(tokens[key])}>
  //                       {tokens[key].name}
  //                     </ListItem>
  //                   ) : null
  //                 )
  //               } else {
  //                 return null;
  //               }
  //             })
  //           }
  //         </List>
  //       </PopoverBody>
  //     </PopoverContent>
  //   </Popover>
  // );

  // const popoverSrcChain = (
  //   <Popover>
  //     <PopoverTrigger>
  //       <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} minW={'200px'}>
  //         {srcChainId
  //           ? chains[srcChainId].name.length > 15
  //             ? chains[srcChainId].name.substring(0, 15) + '...'
  //             : chains[srcChainId].name
  //           : 'src chain'}
  //       </Button>
  //     </PopoverTrigger>
  //     <PopoverContent>
  //       <PopoverBody boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" bgImage="linear-gradient(to right, #0095B6, #00B6D0)" >
  //         <List>
  //           {(chains && !isTestnet) && Object.keys(chains)
  //             .filter((key: string) => !isValidChain(Number(key), sourceToken))
  //             .map((key: string, index: number) => (
  //               <ListItem key={index.toString()} onClick={() => handleSrcChainChange(chains[key].id)}>
  //                 {chains[key].name}
  //               </ListItem>
  //             ))
  //           }
  //           {(chains && isTestnet) && Object.keys(chains)
  //             .filter((key: string) => isValidChain(Number(key), sourceToken))
  //             .map((key: string, index: number) => (
  //               <ListItem key={index.toString()} onClick={() => handleSrcChainChange(chains[key].id)}>
  //                 {chains[key].name}
  //               </ListItem>
  //             ))
  //           }
  //         </List>
  //       </PopoverBody>
  //     </PopoverContent>
  //   </Popover>
  // );

  // const popoverDstChain = (
  //   <Popover >
  //     <PopoverTrigger>
  //       <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} minW={'200px'}>
  //         {dstChainId
  //           ? chains[dstChainId].name.length > 15
  //             ? chains[dstChainId].name.substring(0, 15) + '...'
  //             : chains[dstChainId].name
  //           : 'dst chain'}
  //       </Button>
  //     </PopoverTrigger>
  //     <PopoverContent>
  //       <PopoverBody boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" bgImage="linear-gradient(to right, #0095B6, #00B6D0)" >
  //         <List>
  //           {(chains && !isTestnet) && Object.keys(chains)
  //             .filter((key: string) => !isValidChain(Number(key), sourceToken))
  //             .map((key: string, index: number) => (
  //               <ListItem key={index.toString()} onClick={() => handleDstChainChange(chains[key].id)}>
  //                 {chains[key].name}
  //               </ListItem>
  //             ))
  //           }
  //           {(chains && isTestnet) && Object.keys(chains)
  //             .filter((key: string) => isValidChain(Number(key), sourceToken))
  //             .map((key: string, index: number) => (
  //               <ListItem key={index.toString()} onClick={() => handleDstChainChange(chains[key].id)}>
  //                 {chains[key].name}
  //               </ListItem>
  //             ))
  //           }
  //         </List>
  //       </PopoverBody>
  //     </PopoverContent>
  //   </Popover>
  // );

  return (
    <Box boxShadow="0px 4px 10px rgba(0, 0, 0, 0.7)" background="linear-gradient(to right, #0095B6, #00B6D0)" p={["1em", "2em"]} borderRadius="10px" m="1em auto" maxWidth={["100%", "600px"]} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Text color="white" mb="1em" textAlign="center">Bridge your token from {chains[srcChainId].name} to {chains[dstChainId].name}</Text>
      {status !== 'disconnect' ? <Box p={["1em", "2em"]} borderRadius="10px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box flex="1" mr="1em" display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb="1em">
          <Text color="white">FLAG</Text>
        </Box>
        <Grid templateColumns={["1fr", "1fr auto 1fr"]} gap="1em" alignItems="center" justifyItems="center" w="100%" mb="1em">
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Text color="white">{chains[srcChainId].name}</Text> {/* Nom de la chaîne source */}
          </Box>
          <Icon as={GoArrowRight} />
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Text color="white">{chains[dstChainId].name}</Text> {/* Nom de la chaîne de destination */}
          </Box>
        </Grid>
        <Input
          type="text"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Amount to bridge"
          p="1em"
          w="100%"
          mb="1em"
          borderRadius="5px"
          border="1px solid #ccc"
          _placeholder={{ color: 'white' }} // Ajoutez cette ligne pour changer la couleur du placeholder en blanc
        />
        <Text color={'white'} mb="1em">Your balance: {sourceTokenBalance} {sourceToken.symbol}</Text>
        {(!isApprove && Number(amount) > 0) &&
          <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} onClick={handleApprove} isDisabled={Number(amount) <= 0} mb="1em">Approve Tokens</Button>}
        {sendParamsFee.nativeFees && <Text mb="1em">Estimated Gas Fee: {sendParamsFee.nativeFees} {chains[srcChainId].layerzero.feesSymbols}</Text>}
        <Button color={'white'} sx={{ background: "linear-gradient(to right, #0095B6, #00B6D0)" }} onClick={handleBridge} isDisabled={!isApproved || !sendParamsFee.nativeFees} mb="1em">Bridge</Button>
        <MessageProgress srcTxHash={hash} srcChainId={srcChainId} />
      </Box> : <Text color="white">Please connect your wallet</Text>}
    </Box>
  );
}

export default BridgeForm;
import { polygon } from "../chains/polygon";
import { skaleEuropa } from "../chains/skale/europa";
import { TokenType } from "./tokens";

export const FLAG: TokenType = {
    id: 0,
    name: "Flag",
    symbol: "FLAG",
    decimals: 18,
    logoURI: "https://www.forlootandglory.io/logo192.png",
    addresses: {
        [skaleEuropa.id.toString()]: {
            address: "0xcdF030a3E65f917DFa8d74555A64a5eC5303c88e",
            isAdapter: false,
        },
        [polygon.id.toString()]: {
            address: "0x9111D6446Ac5b88A84cf06425c6286658368542F",
            isAdapter: true,
            OFTAdapter: "0xc83c963785B2dAB7b9Ae7bD71B3a7617940E4047"
        },
    },
};
import { ethers, JsonRpcSigner } from 'ethers';
import TokenABI from "./OFTabi";
import ERC20ABI from "./ERC20abi";
import { Options } from "@layerzerolabs/lz-v2-utilities";
import { TokenType } from './context/tokens/tokens';
import { ChainType } from './context/chains/chains';

// Fonction de pontage des tokens
export async function bridgeTokens(signer: JsonRpcSigner, sendParams: any, nativeFees: string, token: TokenType, srcChainId: number) {
    const tokenAddress = token.addresses[srcChainId]?.address;
    const adapterAddress = token.addresses[srcChainId]?.OFTAdapter;
    const isAdapter = token.addresses[srcChainId]?.isAdapter;

    if (tokenAddress && adapterAddress) {
        const contract = new ethers.Contract(isAdapter ? adapterAddress : tokenAddress, TokenABI, signer);
        try {
            const tx = await contract.send(sendParams, [ethers.parseEther(nativeFees), 0], await signer.getAddress(), { value: ethers.parseEther(nativeFees) });
            await tx.wait();
            return tx;
        } catch (error) {
            console.error("Error during bridging: ", error);
        }
    }
}

export interface SendParamsFee {
    sendParams: any;
    nativeFees: string;
}

// Get the fees for the token bridge
export async function quoteSend(signer: JsonRpcSigner, amount: string, token: TokenType, srcChainId: number, dstChain: ChainType) {
    const tokenAddress = token.addresses[srcChainId]?.address;
    const adapterAddress = token.addresses[srcChainId]?.OFTAdapter;
    const isAdapter = token.addresses[srcChainId]?.isAdapter;
    console.log("tokenAddress: ", tokenAddress);
    console.log("adapterAddress: ", adapterAddress);
    console.log("isAdapter: ", isAdapter);
    if (!amount) {
        amount = "1";
    }
    if (tokenAddress && adapterAddress) {
        const contract = new ethers.Contract(isAdapter ? adapterAddress : tokenAddress, TokenABI, signer);
        const options = Options.newOptions().addExecutorLzReceiveOption(2000000, 0).toHex().toString();

        const sendParams = [
            dstChain.layerzero.eid,
            ethers.zeroPadValue(await signer.getAddress(), 32),
            ethers.parseUnits(amount.toString(), "ether"),
            ethers.parseUnits(amount.toString(), "ether"),
            options,
            "0x",
            "0x"
        ];

        console.log("sendParams: ", sendParams);

        const [nativeFees] = await contract.quoteSend(sendParams, false);
        console.log("quoteSend: ", nativeFees);
        const result: SendParamsFee = {
            sendParams,
            nativeFees: ethers.formatUnits(nativeFees, "ether")
        };
        console.log("result: ", result);
        return result;
    }

}

// Approuver les tokens avant le pontage
export async function approveTokens(signer: JsonRpcSigner, amount: string, token: TokenType, chainId: number) {
    const tokenAddress = token.addresses[chainId]?.address;
    const adapterAddress = token.addresses[chainId]?.OFTAdapter;
    if (tokenAddress && adapterAddress) {
        const contract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
        try {
            const tx = await contract.approve(adapterAddress, ethers.parseUnits(amount, "ether"));
            await tx.wait();
            return tx;
        } catch (error) {
            console.error("Error during token approval: ", error);
        }
    }
}

export async function isApproved(signer: JsonRpcSigner, amount: string, token: TokenType, chainId: number) {
    const tokenAddress = token.addresses[chainId]?.address;
    const adapterAddress = token.addresses[chainId]?.OFTAdapter;
    const isAdapter = token.addresses[chainId]?.isAdapter;
    let contract;
    if (isAdapter && (tokenAddress && adapterAddress)) {
        contract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
        const approvedAmount = await contract.allowance(await signer.getAddress(), adapterAddress);
        return approvedAmount >= ethers.parseUnits(amount, "ether");
    } else {
        return true;
    }
}

// Obtenir le solde du token de l'utilisateur
export async function getTokenBalance(signer: JsonRpcSigner, token: TokenType, chainId: number) {
    console.log("token : ", token)
    console.log("chainId : ", chainId)
    console.log("signer : ", signer)
    const tokenAddress = token.addresses[chainId]?.address;
    console.log("token address : ", tokenAddress)
    let contract;
    if (tokenAddress) {
        contract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
        const balance = await contract.balanceOf(await signer.getAddress());
        return ethers.formatEther(balance);
    }
}
